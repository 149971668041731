:root {
  --bg-card: #1E2330;
  --bg-foter: #07090F;
  --bg-main: #FFFFFF;
  --bg-modal: linear-gradient(180deg, #1E2330 0%, #000614 100%);
  --bg-navbar: #0D0F14;
  --bg-popup: linear-gradient(180deg, #EC0000 0%, #9C0000 100%);
  --bg-text-animation: #1E2330;
  --bt-confirm: linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #434958;
  --bt-login: #FFDE8B;
  --bt-register: #2B00D6;
  --bt-secondary: #C9C9C9;
  --bt-sucess: #00ED09;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

.fixedBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--bt-undefult);
  z-index: 1200;
  opacity: 0.8;
}

.flexCenter {
  display: flex;
}

.boxMargin {
  margin: 0px 28px;
}

.contentPosition {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1300;
  display: block;
  /* width: 100%; */
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 0.15s linear;
  transform: translate(-50%, -50%);
}

.contentPosition::-webkit-scrollbar {
  display: none;
}

.contentPositionFull {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1200;
  padding: 8px 0px;
  height: 100%;
}

.box,
.boxFull {
  /* filter: drop-shadow(0 0 20px hsl(210, 30%, 20%)); */
  border: 2px solid #fff;
  /* border: 2px solid transparent;
  border-image: linear-gradient(180deg, #8a2eff 0%, #ffffff 100%) 25 25 round;
  border-image-slice: 10; */
  border-radius: 16px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
}

.boxTitle {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  /* text-shadow: 0px 0px 4px rgba(113, 246, 255, 0.73); */
  color: var(--bt-defult);
}

.boxBody,
.boxBodyFull {
  position: relative;
  width: auto;
  margin: 1.5rem;
  pointer-events: none;
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.boxContentWidthBig {
  padding: 30px 30px 30px 30px;
  width: 490px;
  height: 100%;
  text-align: left;
  background: var(--bg-modal);
  border-radius: 16px;
}

.boxContentWidthBigImage {
  /* padding: 30px 30px 30px 30px; */
  width: 440px;
  height: 100%;
  /* text-align: left; */
  /* background: var(--bg-modal); */
  /* border-radius: 16px; */
}

.boxContentWidthFull {
  padding: 30px 30px 30px 30px;
  width: 490px;
  height: 100%;
  text-align: left;
  background: var(--bg-modal);
  border-radius: 16px;
}

.boxContentWidthAlert {
  width: 400px;
}

/* .boxContentWidthImg {
  width: 100%;
  max-width: 600px;
} */

/* .boxContentWidthBig2 {
  padding: 30px 40px 30px 40px;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(0, 78, 221, 0.5);
  filter: drop-shadow(0px 0px 4px #001445);
  border-radius: 8px;
}

.boxContentWidthSmall {
  width: 380px;
  height: 440px;
  padding: 60px 50px 12px 50px;
  text-align: left;
  background: rgba(0, 78, 221, 0.5);
  box-shadow: inset 0px 0px 22px #3677ff, inset 0px 0px 160px #000000;
} */

/* .boxContentWidthFullScreen {
  width: 720px;
  height: 470px;
  padding: 30px 50px 12px 50px;
  text-align: left;
  background: rgba(0, 78, 221, 0.5);
  box-shadow: inset 0px 0px 22px #3677ff, inset 0px 0px 160px #000000;
  display: flex;
  position: relative;
} */

.iconClose {
  width: 60px;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.iconClose:hover {
  top: 2px;
}

.closeButtonFullScreen {
  position: absolute;
  top: -2px;
  right: 4px;
  z-index: 1600;
}

.boxContentScroll {
  overflow: scroll;
  height: 100%;
  padding: 8px;
}

.boxContentScroll::-webkit-scrollbar {
  display: none;
}

.closeButton,
.closeButtonFull {
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 1600;
}

.backButton,
.backButtonFull {
  position: absolute;
  top: -16px;
  left: -16px;
  z-index: 1600;
}

a:link {
  color: var(--bt-defult);
}

a:visited {
  color: var(--bt-secondary);
}

@media only screen and (max-width: 980px) {
  .boxContentWidthBig2 {
    height: 100vh;
    padding: 40px 20px 12px 20px;
    width: 330px;
    max-height: 250px;
  }
  .boxContentWidthSmall {
    height: 100vh;
    padding: 40px 20px 12px 20px;
    width: 220px;
    max-height: 250px;
  }
  .boxMargin {
    margin: 0px 12px;
  }
  .boxContentWidthFullScreen {
    padding: 12px 16px 12px 24px;
    width: 66vw;
  }
  .boxContentWidthBig {
    width: 411px;
  }
}

@media only screen and (max-width: 600px) {
  .boxContentWidthAlert,
  .boxContentWidthBig {
    width: 100%;
    height: 100%;
    padding: 30px 0px 20px 0px;
    max-width: 100%;
  }
  .boxContentWidthBigImage {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .boxContentPadding {
    padding: 0px 20px;
  }
  /* .boxContentWidthBig2 {
    width: 300px;
    padding: 40px 10px 12px 10px;
  } */
  .boxContentWidthSmall {
    width: 180px;
    padding: 40px 10px 12px 10px;
  }
  .boxTitle {
    font-size: 20px;
  }
  .contentPosition {
    width: 100%;
  }
  .closeButtonFull {
    right: 6px;
    top: 6px;
  }
  .backButtonFull {
    left: 6px;
    top: 6px;
  }
  .boxBody {
    margin: 16px;
  }
  .boxBodyFull {
    margin: 0px;
  }
  .boxFull {
    min-height: 100vh;
    border-radius: 0px;
    width: 100%;
    border: 0px;
  }
  .boxContentWidthFull {
    width: 90vw;
    min-height: 91vh;
    padding: 40px 20px 40px 20px;
    border-radius: 0px;
  }
  .iconClose {
    width: 50px;
  }
}
