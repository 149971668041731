.mainbox {
    position: relative;
    width: 400px;
    height: 400px;
    display: inline-block;
    border-radius: 100%;
}

.wheelPosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    color: white;
    border-radius: 100%;
}

.wheel {
    width: 340px;
    height: 340px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    transition: all ease 5s;
}

.wheel-wait {
    width: 340px;
    height: 340px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
}

.wheel .number,
.wheel-wait .number {
    position: absolute;
    width: 50%;
    height: 50%;
    background: var(--clr);
    transform-origin: bottom right;
    transform: rotate(calc(45deg * var(--i)));
    clip-path: polygon(0 0, 62% 0, 100% 100%, 0 56%);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.needle {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1300;
}

.animate {
    animation: animateArrow 0.7s ease infinite;
}

@keyframes animateArrow {
    50% {
        top: -20px;
    }
} 

.needleImg {
    width: 48px;
    height: auto;
    cursor: pointer;
}

.spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
}

.spinImg {
    width: 72px;
    height: auto;
    cursor: pointer;
}

.spinPoint {
    background: conic-gradient(from 182.33deg at 50% 53.66%, rgba(255, 255, 255, 0.2) -253.09deg, rgba(255, 255, 255, 0.4) 106.88deg, rgba(255, 255, 255, 0.2) 106.91deg, rgba(255, 255, 255, 0.4) 466.88deg);
    line-height: 21px;
    border-radius: 10px;
    display: inline-block;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
}

.spinPointNumber {
    font-size: 22px;
    font-weight: bold;
    color: #00FF0A;
    margin: 0px 6px;
}

.spinPointText {
    font-weight: 600;
    font-size: 18px;
}

.spinOuterImg {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1200;
}

.wheelReward {
    transform: rotate(-45deg);
}

.wheelRewardPaddingTop {
    padding-top: 35px;
}

.wheelRewardImgMarginTop {
    margin-top: 10px;
}

.wheelRewardImg {
    width: 46px;
    height: auto;
}

.wheelRewardText {
    font-size: 15px;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .mainbox {
        width: 340px;
        height: 340px;
    }
    .wheel,
    .wheel-wait {
        width: 280px;
        height: 280px;
    }
    .wheelRewardText {
        font-size: 13px;
    }
    .wheelRewardImg {
        width: 40px;
    }
}