.bagdeDanger:after {
  content: attr(attribute-bagde-name);
  z-index: 1;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 24px;
  transform: rotate(45deg);
  width: 120px;
  display: block;
  background: #dc3545;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 22px;
  right: -26px;
  clip-path: polygon(
    120px 20px,
    90px -10px,
    30px -10px,
    0px 20px,
    10px 30px,
    110px 30px
  );
}

.ribbonPosition {
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 999;
}

.ribbonPositionAnalyze {
  position: absolute;
  top: -2px;
  left: 18px;
  z-index: 999;
}

.ribbon {
  font-size: 14px;
  text-align: center;
  display: block;
  color: white;
  position: relative;
  padding: 2px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: translate3d(0, 0, 0);
  /* border-top-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  border-radius: 4px;
}

.ribbonShape {
  font-size: 14px;
  text-align: center;
  display: block;
  color: white;
  position: relative;
  padding: 2px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: translate3d(0, 0, 0);
  /* border-radius: 15px 15px 0px 15px; */
}

.ribbonTextHome {
  padding: 2px 8px;
  font-size: 14px;
  /* width: 70px; */
}

.ribbonDanger {
  background: linear-gradient(90deg, #D9D9D9 0%, #FF0000 0.01%, #A40000 100%);
}
.ribbonDanger:after {
  border-top: 1.5rem solid #FF0000;
}
.ribbonWarning {
  background: linear-gradient(90deg, #0066FF 0%, #030096 100%);
}
.ribbonWarning:after {
  border-top: 1.5rem solid #0066FF;
}
.ribbonPrimary {
  background: linear-gradient(90deg, #04CE00 0%, #038D00 100%);
}
.ribbonPrimary:after {
  border-top: 1.5rem solid #04CE00;
}
.ribbonYellow {
  background: linear-gradient(90deg, #FF7A00 0%, #FF0000 100%, #FF0000 100%);
}
.ribbonYellow:after {
  border-top: 1.5rem solid #FF7A00;
}
.ribbonSuccess {
  background: #06C755 ;
}
.ribbonSuccess:after {
  border-top: 1.5rem solid #06C755 ;
}

.promotionTypeLabel {
  font-size: 16px;
  background: #ff0000;
  color: white;
  padding: 2px 24px;
  border-radius: 20px;
  margin-bottom: 8px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .bagdeDanger:after {
    top: 20px;
    right: -30px;
    width: 118px;
    line-height: 18px;
    font-size: 10px;
  }
  .ribbon {
    font-size: 10px;
  }
  .ribbonShape {
    font-size: 10px;
  }
  .ribbonPositionAnalyze {
    left: 12px;
  }
  .promotionTypeLabel {
    font-size: 14px;
  }
  .ribbonPosition {
    top: -2px;
    left: -2px;
  }
  .ribbonTextHome {
    font-size: 10px;
    padding: 1px 4px;
    border-radius: 2px;
    /* width: 50px; */
  }
}
